import React, { useState } from 'react';
import { Heading, View, Card, Text, Button, Flex, Grid, Image, TextField, TextAreaField } from '@aws-amplify/ui-react';
import { createTheme, ThemeProvider } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import logo from './logo.png';
import bannerBackground from './banner-background.jpg';
import pageBackground from './page-background.jpg';

// Add this new import for styled components
import styled from '@emotion/styled';

const theme = createTheme({
  name: 'tagarsoft-theme',
  tokens: {
    colors: {
      brand: {
        primary: { value: '#4a90e2' },
        secondary: { value: '#34495e' },
        accent: { value: '#2ecc71' },
      },
      background: {
        secondary: { value: 'rgba(255, 255, 255, 0.90)' },
      },
      text: {
        primary: { value: 'white' },
        dark: { value: '#333333' },
      },
    },
    radii: {
      small: { value: '8px' },
      medium: { value: '12px' },
      large: { value: '16px' },
    },
    fonts: {
      default: {
        variable: { value: "'Poppins', sans-serif" },
        static: { value: "'Poppins', sans-serif" },
      },
    },
    components: {
      card: {
        boxShadow: { value: '0 4px 6px rgba(0, 0, 0, 0.1)' },
        borderRadius: { value: '8px' },
      },
      button: {
        primary: {
          backgroundColor: { value: '{colors.brand.primary}' },
          color: { value: 'white' },
          _hover: {
            backgroundColor: { value: '{colors.brand.accent}' },
          },
        },
      },
      heading: {
        1: {
          fontWeight: { value: '600' },
        },
      },
    },
  },
});

// Create a styled component for the animated card
const AnimatedCard = styled(Card)`
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s ease forwards;
  animation-delay: ${props => props.index * 0.1}s;

  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  display: flex;
  flex-direction: column;
`;

const CardContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

function App() {
  const [showContactForm, setShowContactForm] = useState(false);
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');
  const [expandedService, setExpandedService] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://formspree.io/f/xyzygykk", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, subject, description }),
      });
      if (response.ok) {
        console.log("Form submitted successfully");
        setEmail('');
        setSubject('');
        setDescription('');
        setShowContactForm(false);
      } else {
        console.error("Form submission failed");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const services = [
    {
      name: 'Custom software development',
      description: 'We create tailored software solutions to meet your unique business needs.',
      details: 'Our custom software development services include web applications, mobile apps, desktop software, and more. We use cutting-edge technologies and follow best practices to deliver high-quality, scalable solutions.',
      color: 'blue'
    },
    {
      name: 'Software consulting',
      description: 'We provide expert advice to help you make informed decisions about your software strategy.',
      details: 'Our software consulting services cover technology selection, architecture design, code reviews, and process improvement. We help you optimize your software development lifecycle and maximize ROI.',
      color: 'green'
    },
    {
      name: 'Cloud consulting',
      description: 'We help you leverage cloud technologies to improve scalability and reduce costs.',
      details: 'Our cloud consulting services include cloud migration strategies, architecture design for cloud-native applications, and optimization of cloud resources. We work with major cloud providers like AWS, Azure, and Google Cloud.',
      color: 'yellow'
    },
    {
      name: 'DevOps',
      description: 'We implement DevOps practices to streamline your development and operations processes.',
      details: 'Our DevOps services include CI/CD pipeline setup, infrastructure as code, containerization, and monitoring solutions. We help you achieve faster deployments, improved reliability, and better collaboration between development and operations teams.',
      color: 'pink'
    },
    {
      name: 'AI and machine learning',
      description: 'We develop AI and ML solutions to help you gain insights and automate processes.',
      details: 'Our AI and machine learning services cover data analysis, predictive modeling, natural language processing, and computer vision. We help you harness the power of AI to drive innovation and efficiency in your business.',
      color: 'purple'
    }
  ];

  const codeBackgroundStyle = {
    backgroundImage: `
      linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)),
      linear-gradient(to right, rgba(100, 100, 100, 0.1) 1px, transparent 1px),
      linear-gradient(to bottom, rgba(100, 100, 100, 0.1) 1px, transparent 1px)
    `,
    backgroundSize: '100% 100%, 20px 20px, 20px 20px',
    backgroundPosition: '0 0, 10px 10px, 10px 10px',
  };

  return (
    <ThemeProvider theme={theme}>
      <View
        style={{
          backgroundImage: `url(${pageBackground})`,
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed',
          backgroundPosition: 'center',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* Banner with background image */}
        <View
          height="300px"
          style={{
            backgroundImage: `url(${bannerBackground})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <View
            height="100%"
            backgroundColor="rgba(0, 0, 0, 0.5)"
            padding="2rem"
            textAlign="center"
          >
            <Flex direction="column" alignItems="center" justifyContent="center" height="100%">
              <Flex direction="row" alignItems="center" marginBottom="1rem">
                <Image
                  src={logo}
                  alt="Tagarsoft Logo"
                  width="80px"
                  marginRight="1rem"
                  backgroundColor="white" // Add a white background to the logo if needed
                  padding="0.5rem"
                  borderRadius="50%"
                />
                <Heading 
                  level={1} 
                  color="white"
                  fontFamily="'Montserrat', sans-serif"
                  fontSize="3.5rem"
                  fontWeight="bold"
                  margin="0"
                  textShadow="2px 2px 4px rgba(0,0,0,0.5)" // Add a text shadow for better visibility
                >
                  Tagarsoft
                </Heading>
              </Flex>
              <Text
                color="white"
                fontSize="1.4rem"
                fontWeight="light"
                fontFamily="'Poppins', sans-serif"
                textShadow="1px 1px 2px rgba(0,0,0,0.5)" // Add a text shadow for better visibility
              >
                Innovative Software Solutions
              </Text>
            </Flex>
          </View>
        </View>

        {/* Main Content */}
        <View padding="2rem" flex="1">
          <Text textAlign="center" fontSize="1.2rem" marginY="2rem" color={theme.tokens.colors.text.primary.value}>
            Tagarsoft is a software company that specializes in developing custom software solutions for businesses. 
            We are a team of experienced developers and designers who are passionate about helping businesses succeed.
          </Text>
          
          <Heading level={2} marginTop="2rem" color={theme.tokens.colors.text.primary.value}>Our Services</Heading>
          <Grid
            templateColumns="repeat(auto-fill, minmax(300px, 1fr))"
            gap="1rem"
            padding="2rem"
          >
            {services.map((service, index) => (
              <AnimatedCard 
                key={index}
                index={index}
                variation="elevated" 
                padding="1.5rem"
                backgroundColor={theme.tokens.colors.background.secondary.value}
                borderRadius={theme.tokens.radii.large.value}
              >
                <CardContent>
                  <Heading level={3} color={theme.tokens.colors.text.dark.value}>{service.name}</Heading>
                  <Text color={theme.tokens.colors.text.dark.value} style={{ flexGrow: 1 }}>{service.description}</Text>
                  <Button 
                    onClick={() => setExpandedService(expandedService === index ? null : index)}
                    marginTop="1rem"
                    backgroundColor={theme.tokens.colors.brand.secondary.value}
                    color="white"
                    borderRadius={theme.tokens.radii.small.value}
                    _hover={{
                      backgroundColor: theme.tokens.colors.brand.accent.value,
                    }}
                  >
                    {expandedService === index ? 'Show Less' : 'Learn More'}
                  </Button>
                  {expandedService === index && (
                    <Text marginTop="1rem" color={theme.tokens.colors.text.dark.value}>{service.details}</Text>
                  )}
                </CardContent>
              </AnimatedCard>
            ))}
          </Grid>
          
          {/* Contact Us section */}
          <Heading level={2} marginTop="3rem" color={theme.tokens.colors.text.primary.value}>Contact Us</Heading>
          <Card 
            variation="elevated" 
            padding="2rem" 
            backgroundColor={theme.tokens.colors.background.secondary.value}
            borderRadius={theme.tokens.radii.large.value}
          >
            <Text color={theme.tokens.colors.text.dark.value} fontSize="1.1rem" marginBottom="1rem">
              Ready to take your business to the next level? Contact us today to discuss your software needs.
            </Text>
            <Button 
              onClick={() => setShowContactForm(!showContactForm)}
              size="large"
              backgroundColor={theme.tokens.colors.brand.secondary.value}
              color="white"
              borderRadius={theme.tokens.radii.small.value}
              _hover={{
                backgroundColor: theme.tokens.colors.brand.accent.value,
              }}
            >
              {showContactForm ? 'Hide Form' : 'Get in Touch'}
            </Button>
            
            {showContactForm && (
              <form onSubmit={handleSubmit}>
                <TextField label="Email" value={email} onChange={(e) => setEmail(e.target.value)} marginTop="1rem" />
                <TextField label="Subject" value={subject} onChange={(e) => setSubject(e.target.value)} marginTop="1rem" />
                <TextAreaField label="Description" value={description} onChange={(e) => setDescription(e.target.value)} marginTop="1rem" />
                <Button type="submit" marginTop="1rem" variation="primary">Submit</Button>
              </form>
            )}
          </Card>
        </View>

        {/* Copyright Footer */}
        <View 
          backgroundColor="rgba(0, 0, 0, 0.7)" 
          padding="1rem" 
          textAlign="center"
        >
          <Text color="white" fontSize="0.9rem">
            © {new Date().getFullYear()} Tagarsoft. All rights reserved.
          </Text>
        </View>
      </View>
    </ThemeProvider>
  );
}

export default App;